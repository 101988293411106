import React from 'react';
import { MarketType1, MarketType2, MarketType3 } from './MarketType';

const renderComponent = (market,item,title) => {

  switch (market.name) {
    case 'moneyline':
      return <MarketType1 data={market} item={item} title={title} />;
    case 'handicap':
      return <MarketType2 data={market} item={item} title={title} />;
    case 'totals':
      return <MarketType2 data={market} item={item} title={title} />;
    case 'winning_margin':
      return <MarketType2 data={market} item={item} title={title} />;
    case 'home_runs':
      return <MarketType3 data={market} item={item} title={title} />;
    case 'pitcher_strikeouts':
      return <MarketType2 data={market} item={item} title={title}/>;
    case 'runs':
      return <MarketType3 data={market} item={item} title={title}/>
    case 'singles':
      return <MarketType3 data={market} item={item} title={title}/>
    case 'triples':
      return <MarketType3 data={market} item={item} title={title}/>
    case 'doubles':
        return <MarketType3 data={market} item={item} title={title}/>
    case 'hits':
        return <MarketType3 data={market} item={item} title={title}/>
    default:
      return null;
  }
};


export default function MarketUI({item,title,propsMarketData}) {
  return (
    <div className='mt-4'>
      {propsMarketData?.map((market) => (
        <div key={market.id}>
          {renderComponent(market,item,title)}
        </div>
      ))}
    </div>
  );
}
