import React, { useContext, useEffect, useState } from 'react'
import {
  Collapse
} from "reactstrap";
import MatchCard from './MatchCard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';
import MoreBetDetails from './Odds/MoreBetOdds';
import { getSportLimits } from '../../../../Utils/sportsbook';
import { OFFLINE_ODD } from '../../../../constant';

const GAME = {id: 'period_full_game', title: 'Game'}
const FIRST_HALF = {id: "period_first_half", title: '1st Half'}
const SECOND_HALF = {id: "period_second_half", title: '2nd Half'}
const FIRST_PERIOD = {id: "period_first_period", title: '1st Period'}
const FIRST_5_INNINGS = {id: "period_first_half", title: '1st 5'}

const TEAM_TYPE_A = ["NFL", "NCAAF", "NBA", "WNBA"];
const TEAM_TYPE_B = ["NCAAM"];
const TEAM_TYPE_C = ["MLB"];

const getAvailablePeriods = (league_data, sportsbook_id) => {
  const checkPeriodOdds = (period_name) => {
    return league_data?.events?.some(event => {
      const sportsbook = event?.odds?.[sportsbook_id]?.[period_name];
      if (!sportsbook) return false;
  
      const { moneyline, spread, total } = sportsbook;
      return (
        moneyline?.HOME?.odd_money !== OFFLINE_ODD ||
        moneyline?.AWAY?.odd_money !== OFFLINE_ODD ||
        spread?.HOME?.odd_money !== OFFLINE_ODD ||
        spread?.AWAY?.odd_money !== OFFLINE_ODD ||
        total?.OVER?.odd_money !== OFFLINE_ODD ||
        total?.UNDER?.odd_money !== OFFLINE_ODD
      );
    });
  };
  
  let period_options = [GAME];
  if (TEAM_TYPE_A.includes(league_data.league_name)) {
    if( checkPeriodOdds(FIRST_HALF.id)) period_options.push(FIRST_HALF);
    if( checkPeriodOdds(SECOND_HALF.id)) period_options.push(SECOND_HALF);
    if( checkPeriodOdds(FIRST_PERIOD.id)) period_options.push(FIRST_PERIOD);
  } else if(TEAM_TYPE_B.includes(league_data.league_name)){
    if( checkPeriodOdds(FIRST_HALF.id)) period_options.push(FIRST_HALF);
    if( checkPeriodOdds(SECOND_HALF.id)) period_options.push(SECOND_HALF);
  } else if(TEAM_TYPE_C.includes(league_data.league_name)){
    if( checkPeriodOdds(FIRST_5_INNINGS.id)) period_options.push(FIRST_5_INNINGS);
  }
  return period_options;
};

const HeadOption = ({ title, value }) => {
  return (
    <div>
      <b>{title}</b><br />
      {<pre>{value}</pre>}
    </div>
  )
}

function LeagueCard({ item,disableLeagueButton,isClickedMoreDetails,setIsClickedMoreDetails }) {
  const [periodsOptions, setPeriodOptions] = useState([GAME]);
  const [period, setPeriod] = useState('period_full_game');
  const [isOpen, setIsOpen] = useState(true);
  const [maxLimit, setMaxLimit] = useState(0);
  const { sports, activeClub, betDetails } = useContext(ValuebetContext);
  const [currentBetMoreDetails,setCuurentBetMoreDetails]=useState(null);
  const {title}=item;


  const toggle = () => {
    setIsOpen(!isOpen)
  }
 

  useEffect(()=>{
    setCuurentBetMoreDetails({...currentBetMoreDetails,title:title})
    },[])

  useEffect(() => {
    let period_options = getAvailablePeriods(item, activeClub?.sportsbook);
    setPeriodOptions(period_options);
  }, [item, activeClub])

  useEffect(()=>{
    disableLeagueButton(isClickedMoreDetails);
  },[isClickedMoreDetails])

  useEffect(() => {
    let max_limit = 0;
    let limits = getSportLimits(sports, item.league_name, 'bet_limits');
    if (limits){
      if (["Straight", "Teaser"].includes(betDetails.bet_info.bet_type)) {
        max_limit = `${limits?.max_bet_all} (All) \n${limits?.max_bet_live} (Live)`;
      } else if (betDetails.bet_info.bet_type === "Parlay") {
        max_limit = `${limits?.max_parlay_all} (All) \n${limits?.max_parlay_live} (Live)`;
      }
    }
    setMaxLimit(max_limit);
  }, [sports, item, betDetails.bet_info.bet_type])

  return (
    <>
    {isClickedMoreDetails?<MoreBetDetails setIsClickedMoreDetails={setIsClickedMoreDetails} currentBetMoreDetails={currentBetMoreDetails} title={title}/>: <div>
      <div className='league-button'>
        <div className='league-head pb-3'>
          <button onClick={toggle} className="league-title-btn">
            <span>{item?.title}</span> {isOpen ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </button>
        </div>
        <div className='link-box'>
          {periodsOptions.map(option => (
            <button key={option.id} className={option.id === period ? 'link-btn-active' : 'link-btn'} onClick={() => setPeriod(option.id)}>{option.title}</button>
          ))}
        </div>
      </div>
      <Collapse className='mb-4 league-list' isOpen={isOpen}>
        <div className='league-head-title text-light'>
          <HeadOption title={'Spread'} value={maxLimit} />
          <HeadOption title={'Totals'} value={maxLimit} />
          <HeadOption title={'ML'} value={maxLimit} />
        </div>
        {item?.events.map((match) => (<MatchCard item={match} league_id={item.league_id} period={period} sportsbook_id={activeClub?.sportsbook} key={match.id}  setIsClickedMoreDetails={setIsClickedMoreDetails} setCuurentBetMoreDetails={setCuurentBetMoreDetails} currentBetMoreDetails={currentBetMoreDetails} title={title}/>))}
      </Collapse>
    </div>}
   
    </>
  )
}

export default LeagueCard