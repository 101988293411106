import React from 'react'
import MlbTable from './MlbTable';

const renderTable=(sport_name,item,title)=>{
    switch(sport_name){
        case 'BASEBALL':
            return <MlbTable item={item} title={title}/>;
        default:
            return null; 
    }
}

const ScoreTable = ({item,title}) => {
  return (
    <div>{renderTable(item.sport_name,item,title)}</div>
  )
}

export default ScoreTable;