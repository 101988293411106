import React, { useLayoutEffect, useState, useContext, useEffect, useRef } from "react";
import TeamCard from "./TeamCard";
import moment from "moment";
import momentTZ from "moment-timezone";
import ValubetContext from "../../../../context/Valuebet/ValuebetContext";
import {
  SCHEDULED_STATUS,
  RUNNING_STATUS,
  BEFORE_START_STATUS,
  OFFLINE_ODD,
  APP_OFFSET,
  LIVE_STATUS,
  FINAL_STATUS,
  PROGRESS_STATUS,
  INFO_STATUS,
} from "../../../../constant";
import CopyText from "../../../../Components/CustComp/CopyText";
import MoreDetailsButton from './MoreDetailsButton';

const isOddsAvailable = (item, sportsbook_id, current_period) => {
  const sportsbook = item?.odds?.[sportsbook_id]?.[current_period];
  if (!sportsbook) return false; // Early exit if sportsbook or odds are unavailable

  const { moneyline, spread, total } = sportsbook;

  // Check if any of the odds are not equal to OFFLINE_ODD
  return (
    moneyline?.HOME?.odd_money !== OFFLINE_ODD ||
    moneyline?.AWAY?.odd_money !== OFFLINE_ODD ||
    spread?.HOME?.odd_money !== OFFLINE_ODD ||
    spread?.AWAY?.odd_money !== OFFLINE_ODD ||
    total?.OVER?.odd_money !== OFFLINE_ODD ||
    total?.UNDER?.odd_money !== OFFLINE_ODD
  );
};

export default function MatchCard({ item, league_id, period, sportsbook_id,setIsClickedMoreDetails,setCuurentBetMoreDetails,currentBetMoreDetails,title}) {  
  const [currentPeriod, setCurrentPeriod] = useState(period);
  const [matchVisibility, setMatchVisibility] = useState(false);
  // eslint-disable-next-line
  const [oddsVisibility, setOddsVisibility] = useState(true); 
  const { activeClub, userDetails } = useContext(ValubetContext);
  let updated_title=title;

  if(updated_title)
  {
    updated_title=updated_title.split("-")[1].replace(/\s+/g, '');

  }

  const statusTimer = useRef(null);
  const preStatus = useRef(null);

  const initMatch = () => {
    let current_period = period;
    if (RUNNING_STATUS.includes(item.event_status) && period === "period_full_game") {
      current_period = "period_live_full_game";
    }
    setCurrentPeriod(current_period);

    if (activeClub?.is_special) {
      if (moment.utc(item?.event_date).utcOffset(APP_OFFSET) <= moment.utc().utcOffset(APP_OFFSET).endOf("day")) {
        let is_available = isOddsAvailable(item, sportsbook_id, current_period);
        setMatchVisibility(is_available);
      } else {
        setMatchVisibility(false);
      }
    } else {
      let is_available = isOddsAvailable(item, sportsbook_id, current_period);
      setMatchVisibility(is_available);
    }

    // if (BEFORE_START_STATUS.includes(item.event_status)) {
    //   if (activeClub?.is_special){
    //     if(moment.utc(item?.event_date).utcOffset(APP_OFFSET) <= moment.utc().utcOffset(APP_OFFSET).endOf('day')){
    //       // setMatchVisibility(true)
    //       let is_available = isOddsAvailable(item, sportsbook_id, current_period);
    //       setMatchVisibility(is_available);
    //     }else{
    //       setMatchVisibility(false)
    //     }
    //   }else{
    //     let is_available = isOddsAvailable(item, sportsbook_id, current_period);
    //     setMatchVisibility(is_available);
    //   }
    // } else {
    //   setMatchVisibility(true)
    //   let is_available = isOddsAvailable(item, sportsbook_id, current_period);
    //   setOddsVisibility(is_available);
    // }
  };

  useLayoutEffect(() => {
    initMatch();
    // eslint-disable-next-line
  }, [item, period, activeClub]);

  

  const highlighterTool = () => {
    if (preStatus.current && preStatus.current !== item.event_status_detail) {
      try {
        if (statusTimer.current) {
          clearTimeout(statusTimer.current)
        }
        let ele = document.getElementById(`status${item.id}`);
        if (ele) {
          ele.classList.add('flash-orange');
        }
        statusTimer.current = setTimeout(() => {
          if (ele) {
            ele.classList.remove('flash-orange')
          }
        }, 6000)
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    highlighterTool();
    return (() => {
      preStatus.current = item.event_status_detail;
    })
    // eslint-disable-next-line
  }, [item.event_status_detail, activeClub]);

  return (
    <>
      {matchVisibility && (
        <div className="league-info">
          {userDetails?.email === "valuebet.app@gmail.com" && (
            <div className="text-secondary font-size-12px ps-1">
              {" "}
              Match ID -{" "}
              <CopyText
                text={item.event_id}
                className="font-size-12px bg-transparent border border-0 text-secondary"
              />{" "}
            </div>
          )}
          <div className="match-status">
            {BEFORE_START_STATUS.includes(item.event_status) && (
              <div className="league-info-head ps-1 me-2">
                <div className="match-time">
                  {moment
                    .utc(item?.event_date)
                    .utcOffset(moment().utcOffset())
                    // .format("ddd, MMM D, h:mm A")}{" "}
                    .format("LLLL")}{" "}
                  {momentTZ.tz(moment.tz.guess()).zoneAbbr()}{" "}
                </div>
                <div className="match-location text-secondary">
                  {`${item.venue_name}, ${item.venue_location}`?.toUpperCase()}
                </div>
              </div>
            )}
            {!SCHEDULED_STATUS.includes(item?.event_status) &&
            <div className="d-flex align-items-center">
              {LIVE_STATUS.includes(item?.event_status) && (
                <div className="font-size-14px border border-success rounded px-2 bg-success me-3">
                  Live
                </div>
              )}
              {FINAL_STATUS.includes(item?.event_status) && (
                <div className="font-size-14px border border-danger rounded px-2 valuebet-bg-color me-3">Final</div>
              )}
              <div />
              {PROGRESS_STATUS.includes(item?.event_status) && 
              <div id={`status${item.id}`} className="match-info me-3" >
                {item.event_status_detail}
              </div>}
              {INFO_STATUS.includes(item.event_status) &&
                <div className="match-info-warn me-3">
                  {item.event_status_detail}
                </div>}
            </div>}
            {!(BEFORE_START_STATUS.includes(item?.event_status) && window.innerWidth < 600) && updated_title === "MLB" && <MoreDetailsButton  item={item} setIsClickedMoreDetails={setIsClickedMoreDetails} setCuurentBetMoreDetails={setCuurentBetMoreDetails} currentBetMoreDetails={currentBetMoreDetails}/>}
          </div>
          <TeamCard
            sport={item.sport_name}
            league_id={league_id}
            match_id={item.id}
            score={item.event_score.score_away}
            match_status={item.event_status}
            team_code={item.away_team_id}
            team_name={item.away_team_name}
            team_type={"AWAY"}
            odds_visibility={oddsVisibility}
            line={sportsbook_id}
            period={currentPeriod}
            moneyline={item.odds[sportsbook_id][currentPeriod]?.moneyline?.AWAY}
            spread={item.odds[sportsbook_id][currentPeriod]?.spread?.AWAY}
            total={item.odds[sportsbook_id][currentPeriod]?.total?.OVER}
            setIsClickedMoreDetails={setIsClickedMoreDetails}
            setCuurentBetMoreDetails={setCuurentBetMoreDetails}
            currentBetMoreDetails={currentBetMoreDetails}
            item={item}
            
          />
          {/* <div className="team-seperator">
            @ <div className="dash-line" />
          </div> */}
          <TeamCard
            sport={item.sport_name}
            league_id={league_id}
            match_id={item.id}
            score={item.event_score.score_home}
            match_status={item.event_status}
            team_code={item.home_team_id}
            team_name={item.home_team_name}
            team_type={"HOME"}
            odds_visibility={oddsVisibility}
            line={sportsbook_id}
            period={currentPeriod}
            moneyline={item.odds[sportsbook_id][currentPeriod]?.moneyline?.HOME}
            spread={item.odds[sportsbook_id][currentPeriod]?.spread?.HOME}
            total={item.odds[sportsbook_id][currentPeriod]?.total?.UNDER}
            setIsClickedMoreDetails={setIsClickedMoreDetails}
            setCuurentBetMoreDetails={setCuurentBetMoreDetails}
            currentBetMoreDetails={currentBetMoreDetails}
            item={item}
          />
        </div>
      )}
    </>
  );
}
