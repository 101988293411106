import React, {useState } from 'react';
import MoreDetailsTab from './MoreDetailsTab';
import { useLocation } from 'react-router';
import { GET_PROPS_MARKET, getValuebet } from '../../../../../../API-Handle/services/auth_curd';
import ScoreTable from './ScoreTable';

const MoreBetDetails = ({currentBetMoreDetails, title }) => {
  const [loading, setLoading] = useState(false);
  const [propsMarketData, setPropsMarketData] = useState([]);
  const [prevSelectType,setPrevSelectType]=useState(null);
  const location = useLocation();
  let item = currentBetMoreDetails;
  const { event_id } = item;
  let club_id = location?.state?.club?.id;

  
  const getPropsMarket = async (selectedType) => {
    if(selectedType!==prevSelectType)
    {
      setLoading(true);
      setPrevSelectType(selectedType)

    }

    let url = `${GET_PROPS_MARKET}/${event_id}?club_id=${club_id}`;
    if (selectedType && selectedType !== "All") {
      url = `${url}&participant_type=${selectedType}`
    }
    let res = await getValuebet(url);
    setLoading(false);
    setPropsMarketData(res);
  }

  return (

    <div >
      <ScoreTable item={item} title={title} />
      <MoreDetailsTab  loading={loading} item={item} title={title} propsMarketData={propsMarketData} getPropsMarket={getPropsMarket}  />
    </div>
  );
};

export default MoreBetDetails;
