import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { accordionSummaryStyles } from '../Utils/BetData';
import MoneylineOdd from '../MarketUI/MoneylineOdd';
import HandicapOdd from '../MarketUI/HandicapOdd';
import HomeRuns from '../MarketUI/HomeRuns';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export const MarketType1 = ({ data, item, title }) => {
    const [market_description, setMarketDescription] = useState("");
    const [openTooltip, setOpenTooltip] = useState(false);

    const showMoreInfo = (e) => {
        e.stopPropagation();
        setMarketDescription(data.market_description);
        setOpenTooltip((prev) => !prev);

    }
    

    return (
        <Box sx={{ mb: 2 }}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    sx={accordionSummaryStyles}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                >
                    <Typography sx={{ fontSize: "16px" }}>{data?.short_description}</Typography>
                    <Tooltip
                        title={market_description}
                        placement="top"
                        open={openTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                    >
                        
                            <InfoOutlinedIcon className='ms-2' onClick={showMoreInfo} fontSize='small' sx={{marginTop:"2px"}} />
                        
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                    {data?.participants.map((team) => (
                        team.lines.map((odd) => (
                            <MoneylineOdd team={team} odd={odd} data={data} key={odd.odd_id} item={item} title={title} />

                        ))


                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export const MarketType2 = ({ data, item, title }) => {
    const [market_description, setMarketDescription] = useState("");
    const [openTooltip, setOpenTooltip] = useState(false);

    const showMoreInfo = (e) => {
        e.stopPropagation();
        setMarketDescription(data.market_description);
        setOpenTooltip((prev) => !prev);

    }
    return (
        <Box sx={{ mb: 2 }}>
            <Accordion sx={{ mb: 2 }} defaultExpanded>
                <AccordionSummary
                    sx={accordionSummaryStyles}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                >
                    <Typography>{data?.short_description}</Typography>
                    <Tooltip
                        title={market_description}
                        placement="top"
                        open={openTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                    >
                        
                            <InfoOutlinedIcon className='ms-2' onClick={showMoreInfo}  fontSize='small' sx={{marginTop:"2px"}}/>
                        
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='d-flex justify-content-between'>
                        {data.participants.map((participant) => (
                            <div key={participant.id} className='header-title'>
                                {participant.name}
                            </div>
                        ))}
                    </div>


                    <div className="d-flex">
                        <div style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}>
                            {data.participants[0].lines.map((line) => (
                                <HandicapOdd data={data} team={data.participants[0]} odd={line} key={line.odd_id} item={item} title={title} />
                            ))}
                        </div>

                        <div style={{ width: 'calc(50% - 5px)' }} >
                            {data.participants[1]?.lines.map((line) => (
                                <HandicapOdd data={data} team={data.participants[1]} odd={line} key={line.odd_id} item={item} title={title} />
                            ))}
                        </div>
                    </div>

                </AccordionDetails>
            </Accordion>
        </Box>
    )

}

export const MarketType3 = ({ data, item, title }) => {
    

    return (
        <>
            {data?.participants.map((member, index) => (
                <HomeRuns member={member} data={data} key={index} item={item} title={title} />


            ))}
        </>

    )
}

// export const Handicap1stHalfMarket = () => {
//     return (
//         <Box sx={{ mb: 2 }}>
//             <Accordion sx={{ mb: 2 }} defaultExpanded>
//                 <AccordionSummary
//                     sx={accordionSummaryStyles}
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel1-content"
//                 >
//                     <Typography>Handicap - 1st Half</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                     <div className='d-flex justify-content-between '>
//                         <div className='header-title'>Punjab X1</div>
//                         <div className='header-title'>Mumbai Indians</div>
//                     </div>
//                     <div className='d-flex justify-content-between mt-2'>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container ' style={{ width: "48%" }} >
//                             <div className='header-title'>-0.5</div>
//                             <div className='text-primary header-title'>+1900</div>
//                         </div>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container' style={{ width: "48%" }}>
//                             <div className='header-title'>+0.5</div>
//                             <div className='text-primary header-title'>-1970</div>
//                         </div>
//                     </div>
//                     <div className='d-flex justify-content-between mt-3'>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container' style={{ width: "48%" }} >
//                             <div className='header-title'>-1.5</div>
//                             <div className='text-primary header-title'>+1961</div>
//                         </div>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container' style={{ width: "48%" }}>
//                             <div className='header-title'>+1.5</div>
//                             <div className='text-primary header-title'>-3090</div>
//                         </div>
//                     </div>
//                     <div className='d-flex justify-content-between mt-3'>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container ' style={{ width: "48%" }} >
//                             <div className='header-title'>-0.5</div>
//                             <div className='text-primary header-title'>+1900</div>
//                         </div>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container' style={{ width: "48%" }}>
//                             <div className='header-title'>+0.5</div>
//                             <div className='text-primary header-title'>-1970</div>
//                         </div>
//                     </div>
//                     <div className='d-flex justify-content-between mt-3'>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container' style={{ width: "48%" }} >
//                             <div className='header-title'>-1.5</div>
//                             <div className='text-primary header-title'>+1961</div>
//                         </div>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container' style={{ width: "48%" }}>
//                             <div className='header-title'>+1.5</div>
//                             <div className='text-primary header-title'>-3090</div>
//                         </div>
//                     </div>
//                 </AccordionDetails>
//             </Accordion>
//         </Box>
//     )

// }

// export const CorrectScoreMarket = () => {
//     return (
//         <Box sx={{ mb: 2 }}>
//             <Accordion sx={{ mb: 2 }} defaultExpanded>
//                 <AccordionSummary
//                     sx={accordionSummaryStyles}
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel1-content"
//                 >
//                     <Typography >Correct Score - Game</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails className='mt-2'>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container'>
//                         <div className='header-title'>Punjab X1 48, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+200</div>
//                     </div>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 mt-3 bet-container'>
//                         <div className='header-title'>Punjab X1 49, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+210</div>
//                     </div>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 mt-3 bet-container'>
//                         <div className='header-title'>Punjab X1 49, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+210</div>
//                     </div>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 mt-3 bet-container'>
//                         <div className='header-title'>Punjab X1 49, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+210</div>
//                     </div>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 mt-3 bet-container'>
//                         <div className='header-title'>Punjab X1 49, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+210</div>
//                     </div>
//                 </AccordionDetails>
//             </Accordion>
//         </Box>
//     )
// }

// export const CorrectScore1stHalfMarket = () => {
//     return (
//         <Box sx={{ mb: 2 }}>
//             <Accordion sx={{ mb: 2 }} defaultExpanded>
//                 <AccordionSummary
//                     sx={accordionSummaryStyles}
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel1-content"
//                 >
//                     <Typography >Correct Score - 1st Half</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails className='mt-2'>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container'>
//                         <div className='header-title'>Punjab X1 48, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+200</div>
//                     </div>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 mt-3 bet-container'>
//                         <div className='header-title'>Punjab X1 49, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+210</div>
//                     </div>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 mt-3 bet-container'>
//                         <div className='header-title'>Punjab X1 49, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+210</div>
//                     </div>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 mt-3 bet-container'>
//                         <div className='header-title'>Punjab X1 49, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+210</div>
//                     </div>
//                     <div className='d-flex justify-content-between border border-primary p-2 rounded-1 mt-3 bet-container'>
//                         <div className='header-title'>Punjab X1 49, Mumbai Indians 67 </div>
//                         <div className='text-primary header-title'>+210</div>
//                     </div>
//                 </AccordionDetails>
//             </Accordion>
//         </Box>
//     )
// }

// export const BothTeamToScoreMarket = () => {
//     return (
//         <Box sx={{ mb: 2 }}>
//             <Accordion sx={{ mb: 2 }} defaultExpanded>
//                 <AccordionSummary
//                     sx={accordionSummaryStyles}
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel1-content"
//                 >
//                     <Typography>Both Team to Score - Game</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails className='mt-2'>
//                     <div className='d-flex justify-content-between'>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container ' style={{ width: "48%" }} >
//                             <div className='header-title'>Yes</div>
//                             <div className='text-primary header-title'>300</div>
//                         </div>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container' style={{ width: "48%" }}>
//                             <div className='header-title'>No</div>
//                             <div className='text-primary header-title'>200</div>
//                         </div>
//                     </div>
//                 </AccordionDetails>
//             </Accordion>
//         </Box>
//     )

// }

// export const BothTeamToScore1stHalfMarket = () => {
//     return (
//         <Box sx={{ mb: 2 }}>
//             <Accordion defaultExpanded>
//                 <AccordionSummary
//                     sx={accordionSummaryStyles}
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel1-content"
//                 >
//                     <Typography>Both Team to Score - 1st Half</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails className='mt-2'>
//                     <div className='d-flex justify-content-between'>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container ' style={{ width: "48%" }} >
//                             <div className='header-title'>Yes</div>
//                             <div className='text-primary header-title'>300</div>
//                         </div>
//                         <div className='d-flex justify-content-between border border-primary p-2 rounded-1 bet-container' style={{ width: "48%" }}>
//                             <div className='header-title'>No</div>
//                             <div className='text-primary header-title'>200</div>
//                         </div>
//                     </div>
//                 </AccordionDetails>
//             </Accordion>
//         </Box>
//     )
// }