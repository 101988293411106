import { useContext, useEffect, useState } from "react";
import ValuebetContext from "../../../../../../../context/Valuebet/ValuebetContext";
import { accordionSummaryStyles, handleClickOnMarket } from "../Utils/BetData";
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OFFLINE_ODD } from "../../../../../../../constant";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



const HomeRunsOdd = ({ odd, team, data,item,title }) => {
    const { betDetails, setBetDetails, removeSelectedBet } = useContext(ValuebetContext);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if ( betDetails?.bets && betDetails.bets.some((bet) => bet.id === odd?.odd_id)) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }
    // eslint-disable-next-line
    }, [betDetails])
    return (
        (odd?.odd_points === OFFLINE_ODD || odd?.odd_money === OFFLINE_ODD) ?
            <div id={odd.id} className="odd-disable-button mt-3"> - </div> :
        (odd?.odd_points || odd?.odd_money) && <div
            role="button"
            className={`d-flex justify-content-between odd-border p-2 rounded-1 bet-container mt-3 ${isSelected ? "selected-odd" : ""}`}
            onClick={() => handleClickOnMarket(team, data, betDetails, setBetDetails, removeSelectedBet, odd,item,title)}
        >
            <div className="header-title ps-2">
            {odd?.odd_points}
            </div>
            <div className="header-title pe-2">
            {odd?.odd_money>0?`+${odd?.odd_money}`:odd?.odd_money}
            </div>
        </div>

    )
}

const HomeRuns = ({ member, data,item,title }) => {
    const [market_description, setMarketDescription] = useState("");
    const [openTooltip, setOpenTooltip] = useState(false);

    const showMoreInfo = (e) => {
        e.stopPropagation();
        setMarketDescription(data.market_description);
        setOpenTooltip((prev) => !prev);

    }
    
    return (
        <Box sx={{ mb: 2 }}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    sx={accordionSummaryStyles}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                >
                    <Typography sx={{ fontSize: "16px" }}>{member.name} ({data.short_description})</Typography>
                    <Tooltip
                        title={market_description}
                        placement="top"
                        open={openTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                    >
                        
                            <InfoOutlinedIcon className='ms-2' sx={{marginTop:"2px"}} onClick={showMoreInfo} fontSize="small" />
                        
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="d-flex">
                        <div style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}>
                            <HomeRunsOdd odd={member.lines[0]} team={member} data={data} item={item} title={title} />

                        </div>

                        <div style={{ width: 'calc(50% - 5px)' }} >
                            <HomeRunsOdd odd={member.lines[1]} team={member} data={data} item={item} title={title} />

                        </div>
                    </div>


                </AccordionDetails>
            </Accordion>
        </Box>


    )

}

export default HomeRuns;