import { getReadablePeriod } from '../../../../../../../Utils/sportsbook';


const createBetData = (team, data, setBetDetails, betDetails, odd,item,title) => {
    const league_name = title && title.split("-")[1].replace(/\s+/g, '');
    const odd_name = item.away_team_name === team.name ? "AWAY" : "HOME";
    const market_name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
    const odd_money = odd?.odd_money;
    const period_name = getReadablePeriod(data?.period_name);
    const odd_points=parseFloat(odd?.odd_points);
  
    const betData = {
        id: odd.odd_id,
        odd_points: odd?.odd_points,
        odd_money: odd_money,
        market_name: market_name,
        sport_name: item.sport_name,
        league_name: league_name,
        league_id: item.league,
        event_id: item.event_id,
        team_code: team.id,
        proposition: true,
        odd_id: team?.lines[0]?.odd_id,
        odd_name: odd_name,
        title: `${title}-${team.name}`,
        sub_title: `${data.short_description} ${odd_points !== 0 ? (odd_points > 0 && data.name === "handicap" ? `+${odd?.odd_points} ` : `${odd?.odd_points} `) : ''}${odd_money > 0 ? `+${odd_money}` : odd_money} for ${period_name}`,
        init_sub_title: `${data.short_description} ${odd_points !== 0 ? (odd_points > 0 && data.name === "handicap" ? `+${odd?.odd_points} ` : `${odd?.odd_points} `) : ''}${odd_money > 0 ? `+${odd_money}` : odd_money} for ${period_name}`,
        period_name: period_name,
        period: data?.period_name,
        line: data?.sportsbook_id,
        market_id:data.market_id,
        period_id:data.period_id,
        sportsbook_id:data.sportsbook_id,
        participant_type:team.type,
        participant_id:team.id
    };
    let tmp_bet = JSON.parse(JSON.stringify(betDetails));
    tmp_bet.bets.push(betData);
    setBetDetails(tmp_bet);
  
  }
  
  export const handleClickOnMarket = (team,data, betDetails, setBetDetails, removeSelectedBet, odd ,item,title) => {
    const isPresent = betDetails?.bets?.some(bet => bet?.id === odd?.odd_id);
    
  
    if (isPresent) {
        removeSelectedBet({ id: odd.odd_id });
    }
    else {
        createBetData(team, data, setBetDetails, betDetails, odd,item,title);
  
    }
  
  
  
  
  }

  export const accordionSummaryStyles = {
    backgroundColor: "#3F3D3D",
    '&.MuiAccordionSummary-root': {
        minHeight: '36px',
    },
    '&.Mui-expanded': {
        minHeight: '36px',
    },
    '& .MuiAccordionSummary-content': {
        margin: '4px 0',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        paddingRight: '8px',
    },
};