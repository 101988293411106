import React, { useContext, useEffect } from 'react'
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext';
import { postValuebet, STRAIGHT_PLACE_BET } from '../../../../../API-Handle/services/auth_curd';
import { errorNotification, successNotification } from '../../../../../Components/CustComp/PPHAlert';
import Checkbox from '../BetComman/Checkbox';
import { useLocation } from 'react-router-dom';
import BetConfirmation from '../BetComman/BetConfirmation';
import { OFFLINE_ODD } from '../../../../../constant';

function BetBottom() {
  const [isSelect, setisSelect] = React.useState(false)
  const [confirm, setConfirm] = React.useState(true)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [resData, setResData] = React.useState(false)
  const location = useLocation();
  const [riskTotal, setRiskTotal] = React.useState(0)
  const [winTotal, setWinTotal] = React.useState(0)
  const { betDetails, setBetDetails, setRootLoader, fetchSingleClub, getStraightBetMaxLimit } = useContext(ValuebetContext);


  useEffect(() => {
    let risk_total = 0;
    let win_total = 0;
    let is_confirm = false;
    for (let bet of betDetails.bets) {
      if (bet.risking) risk_total += bet.risking;
      if (bet.to_win) win_total += bet.to_win;
      if (!is_confirm) {
        if (bet.risking !== bet.init_risk || bet.to_win !== bet.init_win || bet.sub_title !== bet.init_sub_title) {
          is_confirm = true;
        }
      }
    }
    setRiskTotal(risk_total)
    setWinTotal(win_total)
    setConfirm(is_confirm);
  }, [betDetails])

  // Remove All Bets
  const removeAllBets = async () => {
    setBetDetails({ ...betDetails, bets: [] })
  }

  const acceptChanges = async () => {
    let bets = JSON.parse(JSON.stringify(betDetails.bets));
    for (let bet of bets) {
      bet.init_sub_title = bet.sub_title;
      if (bet?.risking !== undefined || bet?.risking !== null) bet.init_risk = bet.risking;
      if (bet?.to_win !== undefined || bet?.to_win !== null) bet.init_win = bet.to_win;
    }
    setBetDetails({ ...betDetails, bets })
  }

  const placeBet = async () => {
    if (betDetails.bets.length < 7) {
      setRootLoader(true)
      let payloads = {
        auto_accept: isSelect,
        bet_type: betDetails.bet_info.bet_type,
        club_id: location?.state?.club?.id,
        odds: []
      }
      for (let bet of betDetails.bets) {
        if (!bet?.risking) {
          errorNotification("Please enter valid risking value!")
          setRootLoader(false)
          return
        }

        let message = false;
        let min_limit = 1;
        let max_limit = getStraightBetMaxLimit(bet);
        if (bet.odd_money === OFFLINE_ODD) {
          message = "Please remove ticket which is no longer available.";
        } else if (bet.odd_money > 0) {
          if (max_limit < bet?.risking || bet?.risking < min_limit) {
            message = `Risking value must be greater-than or equal to ${min_limit} and less-than or equal to ${max_limit}.`;
          }
        } else {
          let risk_max_limit = max_limit / ((1 - (100 / bet.odd_money)) - 1);
          risk_max_limit = Number(risk_max_limit.toFixed(2));
          if (risk_max_limit < bet?.risking || bet?.risking < min_limit) {
            message = `Risking value must be greater-than or equal to ${min_limit} and less-than or equal to ${risk_max_limit}.`;
          }
        }

        if (message) {
          errorNotification(message)
          setRootLoader(false)
          return
        }
        const odd_data = {
          odd_id: bet.id,
          risking: bet.risking,
          to_win: bet.to_win,
          odd_money: bet.odd_money,
          odd_points: bet.odd_points
        }

        if (bet?.proposition) {

          const proposition_data = {
            proposition: bet.proposition,
            event_id: bet.event_id,
            sportsbook_id: bet.sportsbook_id,
            market_id: bet.market_id,
            participant_id: bet.participant_id,
            participant_type: bet.participant_type,
            period_id: bet.period_id
          }
          Object.assign(odd_data, proposition_data);


        }
        payloads.odds.push(odd_data);
      }
      let res = await postValuebet(STRAIGHT_PLACE_BET, payloads)
      if (res?.message) {
        successNotification(res.message)
        setResData(res)
        setIsSuccess(true)
        fetchSingleClub(location?.state?.club?.id)
      }
      setRootLoader(false)
    }
  }

  const toggleOnBetSuccess = () => {
    removeAllBets();
    setIsSuccess(false);
  }

  const timeSleep = (ms) => {
    setTimeout(function (buttons) {
    }, ms);
    return true;
  }

  const maxAllBets = async () => {
    let buttons = document.getElementsByClassName('straigth-max');
    for (var i = 0; i < buttons.length; i++) {
      await timeSleep(100);
      buttons[i].click();
    }
  }

  return (
    <>
      <BetConfirmation isActive={isSuccess} toggleHandler={toggleOnBetSuccess} data={resData} />
      <div className='d-flex justify-content-center'>
        <button className='btn btn-sm bg-warning max-all-bet mb-3' onClick={maxAllBets}>
          Max All
        </button>
      </div>
      <div className='bet-bottom'>
        <p className='bet-bottom-text'>Risking {Number(riskTotal).toFixed(2)} <small className="font-size-10px">Chips</small></p>
        <p className='bet-bottom-text'>To Win {Number(winTotal).toFixed(2)} <small className="font-size-10px">Chips</small></p>
        <div>
          <Checkbox label={'Auto Accept Changes'} select={isSelect} onChange={() => setisSelect(!isSelect)} />
          {confirm && !isSelect ?
            <div className='mb-2'>
              <button className='btn btn-secondary btn-sm w-40' onClick={removeAllBets}>
                Cancel
              </button>
              <button className='btn btn-success btn-sm w-40 ms-2' onClick={acceptChanges}>
                Confirm
              </button>
            </div>
            : location?.state?.club?.label ?
              <button className='bet-place-btn' onClick={placeBet}>
                Place bet
              </button>
              : <div className='mb-2'>
                <button className='btn btn-sm w-40 btn-orange text-white' onClick={() => document.getElementById("signup_1").click()}>
                  Signup
                </button>
                <button className='btn btn-sm w-40 btn-orange text-white ms-2' onClick={() => document.getElementById("login_1").click()}>
                  Login
                </button>
              </div>
          }
          <button className='remove-selection-btn' onClick={removeAllBets}>
            Remove selections
          </button>
        </div>
      </div>
    </>
  )
}

export default BetBottom